@import './index.scss';

@mixin darkTheme() {
  background-color: rgba($color: #282828, $alpha: 1);
  color: #fff;
  a {
    color: #d3da0a;
  }
  .home {
    .art {
      filter: drop-shadow(5px 5px 10px #fff);
      .cover {
        content: url('images/rashashalt.png');
      }
    }
  }
}

.App {
  text-align: center;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgba($color: #0a5d45, $alpha: 1);
  &.dark {
    @include darkTheme();
  }
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    scroll-snap-align: start;
    flex-direction: column;
  }
  .home {
    .art {
      height: 30vh;
      filter: drop-shadow(5px 5px 10px);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        filter: drop-shadow(5px 5px 20px);
      }
      .pointer {
        position: absolute;
        right: 45%;
        top: 50%;
        opacity: 0;
        animation: notify 5s 1s linear 1;
        z-index: 1;
        img {
          content: url('images/pointer.png');
          filter: drop-shadow(5px 5px 10px);
          height: 8vh;
          animation: press 1s linear infinite;
        }
      }
      .cover {
        animation: float 8s alternate infinite;

        content: url('images/rashash.png');
        border-radius: 15px;
        height: 100%;
      }
    }
  }
  .tracks {
    justify-content: space-around;
    @media (min-width: 750px) {
      flex-direction: row !important;
      align-items: center;
      .list {
        ul {
          width: 50vw !important;
          li {
            height: 15vh !important;
          }
        }
      }
      .player {
        width: 50vw !important;
      }
    }
    .list {
      ul {
        width: 100vw;
        padding: 0;
        margin: 0;
        li {
          cursor: pointer;
          background-color: rgba($color: #000000, $alpha: 0.1);
          height: 8vh;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.25);
          }
          &.selected {
            background-color: rgba($color: #000000, $alpha: 0.2);
          }
        }
      }
    }
    .player {
      width: 100vw;
    }
  }
  .links {
    a {
      margin: 20px;
    }
  }
}

@keyframes press {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}

@keyframes notify {
  0% {
    opacity: 0;
    display: inline !important;
  }
  25% {
    opacity: 1;
    display: inline !important;
  }
  75% {
    opacity: 1;
    display: inline !important;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes flip {
  0%,
  80% {
    transform: rotateY(360deg);
  }
}

.slide {
  transform: translateY(50px);
  opacity: 0;
  transition: transform 500ms ease-in;
  &.appear {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fade {
  opacity: 0;
  transition: opacity 650ms ease-in;
  &.appear {
    opacity: 1;
  }
}
